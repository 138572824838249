module.exports={
  "_args": [
    [
      {
        "raw": "prismic.io@^3.3.1",
        "scope": null,
        "escapedName": "prismic.io",
        "name": "prismic.io",
        "rawSpec": "^3.3.1",
        "spec": ">=3.3.1 <4.0.0",
        "type": "range"
      },
      "/Users/christophe/Projects/prismic-webapp-brigittehenke"
    ]
  ],
  "_from": "prismic.io@>=3.3.1 <4.0.0",
  "_id": "prismic.io@3.3.1",
  "_inCache": true,
  "_location": "/prismic.io",
  "_nodeVersion": "4.6.2",
  "_npmOperationalInternal": {
    "host": "packages-18-east.internal.npmjs.com",
    "tmp": "tmp/prismic.io-3.3.1.tgz_1484132951481_0.3528884439729154"
  },
  "_npmUser": {
    "name": "arnaudlewis",
    "email": "arnaud.lewis@gmail.com"
  },
  "_npmVersion": "2.15.11",
  "_phantomChildren": {},
  "_requested": {
    "raw": "prismic.io@^3.3.1",
    "scope": null,
    "escapedName": "prismic.io",
    "name": "prismic.io",
    "rawSpec": "^3.3.1",
    "spec": ">=3.3.1 <4.0.0",
    "type": "range"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/prismic.io/-/prismic.io-3.3.1.tgz",
  "_shasum": "90b1e7ab68b7c5a006b2c1fcf0631a8459d0a7b4",
  "_shrinkwrap": null,
  "_spec": "prismic.io@^3.3.1",
  "_where": "/Users/christophe/Projects/prismic-webapp-brigittehenke",
  "bugs": {
    "url": "https://github.com/prismicio/javascript-kit/issues"
  },
  "dependencies": {},
  "description": "JavaScript development kit for prismic.io",
  "devDependencies": {
    "babel-preset-es2015": "^6.3.13",
    "babelify": "^7.2.0",
    "browserify": "^12.0.1",
    "chai": "*",
    "codeclimate-test-reporter": "0.0.4",
    "es6-promise": "^3.2.1",
    "eslint": "^2.12.0",
    "istanbul": "^0.4.4",
    "jsdoc": "^3.4.0",
    "mocha": "*",
    "rimraf": "^2.5.4",
    "uglify-js": "^2.6.1",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0"
  },
  "directories": {},
  "dist": {
    "shasum": "90b1e7ab68b7c5a006b2c1fcf0631a8459d0a7b4",
    "tarball": "https://registry.npmjs.org/prismic.io/-/prismic.io-3.3.1.tgz"
  },
  "gitHead": "2190bcedced7dacc54581224b3436abd6cc709d8",
  "homepage": "https://github.com/prismicio/javascript-kit#readme",
  "keywords": [
    "prismic",
    "prismic.io",
    "cms",
    "content",
    "api"
  ],
  "license": "Apache-2.0",
  "main": "lib/prismic.js",
  "maintainers": [
    {
      "name": "arnaudlewis",
      "email": "arnaud.lewis@gmail.com"
    },
    {
      "name": "dohzya",
      "email": "dohzya@gmail.com"
    },
    {
      "name": "eloisant",
      "email": "eloisant@gmail.com"
    },
    {
      "name": "sre",
      "email": "srenault.contact@gmail.com"
    }
  ],
  "name": "prismic.io",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/prismicio/javascript-kit.git"
  },
  "scripts": {
    "build": "scripts/browser.js",
    "docs": "jsdoc dist/prismic.io.js README.md -d docs",
    "lint": "eslint lib",
    "postbuild": "npm run docs",
    "posttest": "eslint lib",
    "predocs": "rimraf ./docs",
    "prepublish": "npm run uglify",
    "preuglify": "npm run build",
    "test": "istanbul cover _mocha test/",
    "uglify": "uglifyjs -c -o=dist/prismic.io.min.js dist/prismic.io.js"
  },
  "url": "https://github.com/prismicio/javascript-kit",
  "version": "3.3.1"
}
